@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@600;700&family=Raleway:wght@400;700;800&family=Open+Sans:wght@400&display=swap);
body {
  margin: 0;
  font-family: 'Montserrat', 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F4F9FF;
}
body.loading * {
    cursor: wait;
}

#content.modal-opened {
    height: 320px;
    overflow: hidden;
}
.clearfix {
   display: block;
   content: "";
   clear: both;
}
.mt24 {
    margin-top: 24px;
}
.mt32 {
    margin-top: 32px;
}
.mb24 {
    margin-bottom: 24px;
}
.mb64 {
    margin-bottom: 64px;
}
.Mui-error label {
    color: #FF5800;
}
.Mui-error fieldset {
    border-color: #FF5800;
    color: #FF5800;
}
.error {
    color: #FF5800;
    font-size: 13px;
    display: block;
    margin-bottom: 16px;
    font-family: 'Raleway';
}
.display-none {
    display: none;
}
.slide-up, .slide-down {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;
}
.slide-down {
    max-height: 235px;
}
.preview-watermark {
    position: absolute;
    top: -400px;
    left: -500px;
    height: 2000px;
    width: 2000px;
    text-transform: uppercase;
    font-size: 30px;
    color: #0162CA;
    background-color: #d6d6d6;
    opacity: 0.1;
    transform: rotate(-45deg);
    line-height:100px;
}
:root {
  --font-family-raleway: 'Raleway';
  --font-family-montserrat: 'Montserrat';
  --font-style-normal: normal;
  --font-weight-600: 600px;
  --font-weight-medium: medium;
  --font-weight-normal: normal;
  --font-weight-bold: bold;
  --font-size-11: 11px;
  --font-size-14: 14px;
  --font-size-15: 15px;
  --font-size-16: 16px;
  --font-size-25: 25px;
  --character-spacing-0: 0px;
  --line-spacing-15: 15px;
  --line-spacing-18: 18px;
  --line-spacing-23: 23px;
  --line-spacing-25: 25px;
  --line-spacing-35: 35px;
  --decoration-underline: underline;
  --text-transform-uppercase: uppercase;
}

/* Character Styles */
.caption {
  font-family: 'Raleway';
  font-family: var(--font-family-raleway);
  font-style: normal;
  font-style: var(--font-style-normal);
  font-weight: medium;
  font-weight: var(--font-weight-medium);
  font-size: 11px;
  font-size: var(--font-size-11);
  line-height: 15px;
  line-height: var(--line-spacing-15);
  letter-spacing: 0px;
  letter-spacing: var(--character-spacing-0);
  color: #5c7692;
}
.body2-black {
  font-family: 'Raleway';
  font-family: var(--font-family-raleway);
  font-style: normal;
  font-style: var(--font-style-normal);
  font-weight: medium;
  font-weight: var(--font-weight-medium);
  font-size: 15px;
  font-size: var(--font-size-15);
  line-height: 23px;
  line-height: var(--line-spacing-23);
  letter-spacing: 0px;
  letter-spacing: var(--character-spacing-0);
  color: #000000;
}
.body2-grey-link {
  font-family: 'Raleway';
  font-family: var(--font-family-raleway);
  font-style: normal;
  font-style: var(--font-style-normal);
  font-weight: medium;
  font-weight: var(--font-weight-medium);
  font-size: 15px;
  font-size: var(--font-size-15);
  line-height: 23px;
  line-height: var(--line-spacing-23);
  letter-spacing: 0px;
  letter-spacing: var(--character-spacing-0);
  color: #5c7692;
  text-decoration: underline;
  -webkit-text-decoration: var(--decoration-underline);
          text-decoration: var(--decoration-underline);
}
.body2-grey {
  font-family: 'Raleway';
  font-family: var(--font-family-raleway);
  font-style: normal;
  font-style: var(--font-style-normal);
  font-weight: medium;
  font-weight: var(--font-weight-medium);
  font-size: 15px;
  font-size: var(--font-size-15);
  line-height: 23px;
  line-height: var(--line-spacing-23);
  letter-spacing: 0px;
  letter-spacing: var(--character-spacing-0);
  color: #5c7692;
}
.button-white {
  font-family: 'Montserrat';
  font-family: var(--font-family-montserrat);
  font-style: normal;
  font-style: var(--font-style-normal);
  font-weight: normal;
  font-weight: var(--font-weight-normal);
  font-size: 14px;
  font-size: var(--font-size-14);
  line-height: 18px;
  line-height: var(--line-spacing-18);
  letter-spacing: 0px;
  letter-spacing: var(--character-spacing-0);
  color: #ffffff;
  text-transform: uppercase;
  text-transform: var(--text-transform-uppercase);
}
.body1-black {
  font-family: 'Montserrat';
  font-family: var(--font-family-montserrat);
  font-style: normal;
  font-style: var(--font-style-normal);
  font-weight: normal;
  font-weight: var(--font-weight-normal);
  font-size: 16px;
  font-size: var(--font-size-16);
  line-height: 25px;
  line-height: var(--line-spacing-25);
  letter-spacing: 0px;
  letter-spacing: var(--character-spacing-0);
  color: #000000;
}
.body1-bold-black {
  font-family: 'Montserrat';
  font-family: var(--font-family-montserrat);
  font-style: normal;
  font-style: var(--font-style-normal);
  font-weight: bold;
  font-weight: var(--font-weight-bold);
  font-size: 16px;
  font-size: var(--font-size-16);
  line-height: 25px;
  line-height: var(--line-spacing-25);
  letter-spacing: 0px;
  letter-spacing: var(--character-spacing-0);
  color: #000000;
}
h1 {
  font-family: 'Montserrat';
  font-family: var(--font-family-montserrat);
  font-style: normal;
  font-style: var(--font-style-normal);
  font-weight: bold;
  font-weight: var(--font-weight-bold);
  font-size: 25px;
  font-size: var(--font-size-25);
  line-height: 35px;
  line-height: var(--line-spacing-35);
  letter-spacing: 0px;
  letter-spacing: var(--character-spacing-0);
  color: #000000;
}
.body1-grey {
  font-family: 'Montserrat';
  font-family: var(--font-family-montserrat);
  font-style: normal;
  font-style: var(--font-style-normal);
  font-weight: 600px;
  font-weight: var(--font-weight-600);
  font-size: 16px;
  font-size: var(--font-size-16);
  line-height: 25px;
  line-height: var(--line-spacing-25);
  letter-spacing: 0px;
  letter-spacing: var(--character-spacing-0);
  color: #5c7692;
}
.block-label {
  display: inline-block;
  font: normal normal normal 13px/20px 'Montserrat';
  letter-spacing: 0;
  color: #627A97;
  text-transform: uppercase;
}

