:root {
  --font-family-raleway: 'Raleway';
  --font-family-montserrat: 'Montserrat';
  --font-style-normal: normal;
  --font-weight-600: 600px;
  --font-weight-medium: medium;
  --font-weight-normal: normal;
  --font-weight-bold: bold;
  --font-size-11: 11px;
  --font-size-14: 14px;
  --font-size-15: 15px;
  --font-size-16: 16px;
  --font-size-25: 25px;
  --character-spacing-0: 0px;
  --line-spacing-15: 15px;
  --line-spacing-18: 18px;
  --line-spacing-23: 23px;
  --line-spacing-25: 25px;
  --line-spacing-35: 35px;
  --decoration-underline: underline;
  --text-transform-uppercase: uppercase;
}

/* Character Styles */
.caption {
  font-family: var(--font-family-raleway);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-11);
  line-height: var(--line-spacing-15);
  letter-spacing: var(--character-spacing-0);
  color: #5c7692;
}
.body2-black {
  font-family: var(--font-family-raleway);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-15);
  line-height: var(--line-spacing-23);
  letter-spacing: var(--character-spacing-0);
  color: #000000;
}
.body2-grey-link {
  font-family: var(--font-family-raleway);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-15);
  line-height: var(--line-spacing-23);
  letter-spacing: var(--character-spacing-0);
  color: #5c7692;
  text-decoration: var(--decoration-underline);
}
.body2-grey {
  font-family: var(--font-family-raleway);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-15);
  line-height: var(--line-spacing-23);
  letter-spacing: var(--character-spacing-0);
  color: #5c7692;
}
.button-white {
  font-family: var(--font-family-montserrat);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-normal);
  font-size: var(--font-size-14);
  line-height: var(--line-spacing-18);
  letter-spacing: var(--character-spacing-0);
  color: #ffffff;
  text-transform: var(--text-transform-uppercase);
}
.body1-black {
  font-family: var(--font-family-montserrat);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-normal);
  font-size: var(--font-size-16);
  line-height: var(--line-spacing-25);
  letter-spacing: var(--character-spacing-0);
  color: #000000;
}
.body1-bold-black {
  font-family: var(--font-family-montserrat);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-16);
  line-height: var(--line-spacing-25);
  letter-spacing: var(--character-spacing-0);
  color: #000000;
}
h1 {
  font-family: var(--font-family-montserrat);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-25);
  line-height: var(--line-spacing-35);
  letter-spacing: var(--character-spacing-0);
  color: #000000;
}
.body1-grey {
  font-family: var(--font-family-montserrat);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-600);
  font-size: var(--font-size-16);
  line-height: var(--line-spacing-25);
  letter-spacing: var(--character-spacing-0);
  color: #5c7692;
}
.block-label {
  display: inline-block;
  font: normal normal normal 13px/20px 'Montserrat';
  letter-spacing: 0;
  color: #627A97;
  text-transform: uppercase;
}
