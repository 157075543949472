@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600;700&family=Raleway:wght@400;700;800&family=Open+Sans:wght@400&display=swap');

body {
  margin: 0;
  font-family: 'Montserrat', 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F4F9FF;
}
body.loading * {
    cursor: wait;
}

#content.modal-opened {
    height: 320px;
    overflow: hidden;
}
.clearfix {
   display: block;
   content: "";
   clear: both;
}
.mt24 {
    margin-top: 24px;
}
.mt32 {
    margin-top: 32px;
}
.mb24 {
    margin-bottom: 24px;
}
.mb64 {
    margin-bottom: 64px;
}
.Mui-error label {
    color: #FF5800;
}
.Mui-error fieldset {
    border-color: #FF5800;
    color: #FF5800;
}
.error {
    color: #FF5800;
    font-size: 13px;
    display: block;
    margin-bottom: 16px;
    font-family: 'Raleway';
}
.display-none {
    display: none;
}
.slide-up, .slide-down {
    max-height: 0;
    overflow: hidden;
    -webkit-transition: max-height 0.5s ease-in-out;
    -moz-transition: max-height 0.5s ease-in-out;
    -o-transition: max-height 0.5s ease-in-out;
    transition: max-height 0.5s ease-in-out;
}
.slide-down {
    max-height: 235px;
}
.preview-watermark {
    position: absolute;
    top: -400px;
    left: -500px;
    height: 2000px;
    width: 2000px;
    text-transform: uppercase;
    font-size: 30px;
    color: #0162CA;
    background-color: #d6d6d6;
    opacity: 0.1;
    transform: rotate(-45deg);
    line-height:100px;
}